<template>
    <div v-frag>
        <div class="row" style="padding-bottom:60px">
            <div class="col-md-2 hidden-sm hidden-xs"></div>
                <div class="col-md-8 col-sm-12">
                    <div class="panel panel-default htp">
                        <div class="panel-body">
                            <div class="how-to-play">
                                <div>
                                    <h4 class="how-to-play-title">
                                        <span>{{ $t('how-to-play.play-via-web') }}</span>
                                    </h4>
                                    <ul class="list-unstyled htp">
                                        <li><a @click.stop="showdiv('withdraw_web')">{{ $t('deposit.how-to-deposit') }}</a></li>
                                        <li><a @click.stop="showdiv('reg_web')">{{ $t('how-to-play.registration') }}</a></li>                    
                                        <li><a @click.stop="showdiv('web_bet')">{{ $t('how-to-play.bet') }}</a></li>
                                        <li><a @click.stop="showdiv('account_web')">{{ $t('header.my-account') }}</a></li>
                                    </ul>
                                </div>
                                <div>
                                    <h4 class="how-to-play-title">
                                        <span>{{ $t('how-to-play.play-via-ussd') }}</span>
                                    </h4>
                                    <ul class="list-unstyled htp">
                                        <li><a @click.stop="showdiv('dep_sms')">{{ $t('header.deposit') }}</a></li> 
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div  v-show="div_name=='dep_sms'">
                        <div class="welcome">
                            <h4>{{ $t('deposit-ussd.deposit-ussd') }}</h4>
                            <button class="terms-accordion" @click="toggleAccordian">Lumicash</button>
                            <div class="terms-conditions">
                                <div class="terms-body">
                                    <ol style="margin-left: -27px;">
                                        <li>{{ $t('lumicash.li-6') }}</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-show="div_name === 'reg_web'">
                        <div class="welcome">
                            <h4>{{ $t('register-with-web.register-web') }}</h4>
                            <button class="terms-accordion"
                                @click="toggleAccordian">{{ $t('register-with-web.part-one') }}</button>
                            <div class="terms-conditions">
                                <div class="terms-body">
                                    {{ $t('register-with-web.part-one-details') }}
                                </div>
                            </div>
                            <button class="terms-accordion"
                                @click="toggleAccordian">{{ $t('register-with-web.part-two') }}</button>
                            <div class="terms-conditions">
                                <div class="terms-body">
                                    <ul style="list-style:decimal">
                                        <li>{{ $t('register-with-web.part-two-details-step-1') }}</li>
                                        <li>{{ $t('register-with-web.part-two-details-step-2') }}</li>
                                        <li>{{ $t('register-with-web.part-two-details-step-3') }}</li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-show="div_name === 'withdraw_web'">
                        <div class="welcome">
                            
                            <h4>{{ $t('deposit.how-to-deposit') }}</h4>
                            <button class="terms-accordion" @click="toggleAccordian">Lumicash</button>
                            <div class="terms-conditions">
                                <div class="terms-body">
                                <h5><b>{{ $t('header.deposit') }}</b></h5>
                                <ol style="margin-left: -27px;">
                                    <li>{{ $t('lumicash.li-1') }}</li>
                                    <li>{{ $t('lumicash.li-2') }}</li>
                                    <li>{{ $t('lumicash.li-3') }}</li>
                                    <li>{{ $t('lumicash.li-4') }}</li>
                                    <li>{{ $t('lumicash.li-5') }}</li>
                                </ol>
                                <h5><b>{{ $t('header.withdraw') }}</b></h5>
                                <ol style="margin-left: -27px;">
                                    <li>{{ $t('lumicash.w-li-1') }}</li>
                                    <li>{{ $t('lumicash.w-li-2') }}</li>
                                    <li>{{ $t('lumicash.w-li-3') }}</li>
                                    <li>{{ $t('lumicash.w-li-4') }}</li>
                                    
                                </ol>
                                </div>
                            </div>

                            <button class="terms-accordion" @click="toggleAccordian">Pesaflash</button>
                            <div class="terms-conditions">
                                <div class="terms-body">
                                    <!-- {{ 'withdrawal-via-web.part-one-details' | translate }} -->
                                    <!-- <img class="img-responsive img-logo" src="assets/images/ecocash.png" alt="logo"> -->
                                    <h5><b>{{ $t('header.deposit') }}</b></h5>
                                    <ol style="margin-left: -27px;">
                                        <li>{{ $t('deposit.li-1') }}</li>
                                        <li>{{ $t('deposit.li-2') }}</li>
                                        <li>{{ $t('deposit.li-3') }}</li>
                                        <li>{{ $t('deposit.li-4') }}</li>
                                        <li>{{ $t('deposit.li-5') }}</li>
                                        <li>{{ $t('deposit.li-6') }}</li>
                                        <li>{{ $t('deposit.li-7') }}</li>
                                        <li>{{ $t('deposit.li-8') }}</li>
                                        <li>{{ $t('deposit.li-9') }}</li>
                                        <li>{{ $t('deposit.li-10') }}</li>
                                    </ol>
                                    <h5><b>{{ $t('header.withdraw') }}</b></h5>
                                    <ol style="margin-left: -27px;">
                                        <li>{{ $t('withdraw.li-1') }}</li>
                                        <li>{{ $t('withdraw.li-2') }}</li>
                                        <li>{{ $t('withdraw.li-3') }}</li>
                                        <li>{{ $t('withdraw.li-4') }}</li>
                                        <li>{{ $t('withdraw.li-6') }}</li>
                                        <li>{{ $t('withdraw.li-7') }}</li>
                                        <li>{{ $t('withdraw.li-8') }}</li>
                                        <li>{{ $t('withdraw.li-9') }}</li>
                                        <li>{{ $t('withdraw.li-10') }}</li>
                                        <li>{{ $t('withdraw.li-11') }}</li>
                                    </ol>

                                    <!-- <img class="img-responsive img-logo" src="assets/images/slogan.png" alt="logo"> -->
                                </div>
                            </div>

                            <button class="terms-accordion" @click="toggleAccordian">{{ $t('payway.name') }}</button>
                            <div class="terms-conditions">
                                <div class="terms-body">
                                    <!-- {{ 'withdrawal-via-web.part-one-details' | translate }} -->
                                    <!-- <img class="img-responsive img-logo" src="assets/images/ecocash.png" alt="logo"> -->
                                    <h5><b>{{ $t('payway.deposit') }}</b></h5>
                                    <ol style="margin-left: -27px;">
                                        <li>{{ $t('payway.d1') }}</li>
                                        <li>{{ $t('payway.d2') }}</li>
                                        <li>{{ $t('payway.d3') }}</li>
                                    </ol>
                                    <h5><b>{{ $t('payway.withdraw') }}</b></h5>
                                    <ol style="margin-left: -27px;">
                                        <li>{{ $t('payway.d1') }}</li>
                                        <li>{{ $t('payway.w1') }}</li>
                                        <li>{{ $t('payway.w2') }}</li>
                                        <li>{{ $t('payway.w3') }}</li>
                                        <li>{{ $t('payway.w4') }}</li>
                                    </ol>

                                    <!-- <img class="img-responsive img-logo" src="assets/images/slogan.png" alt="logo"> -->
                                </div>
                            </div>

                            
                        </div>
                    </div>

                    <div v-show="div_name === 'web_bet'">
                        <div class="welcome">
                            <h4>{{ $t('bet.bet') }}</h4>
                            <button class="terms-accordion" @click="toggleAccordian">{{ $t('bet.part-one') }}</button>
                            <div class="terms-conditions">
                                <div class="terms-body">
                                    {{ $t('bet.part-one-details') }}
                                </div>
                            </div>
                            <button class="terms-accordion" @click="toggleAccordian">{{ $t('bet.part-two') }}</button>
                            <div class="terms-conditions">
                                <div class="terms-body">
                                    {{ $t('bet.part-two-details') }}
                                </div>
                            </div>
                            <button class="terms-accordion" @click="toggleAccordian">{{ $t('bet.part-three') }}</button>
                            <div class="terms-conditions">
                                <div class="terms-body">
                                    {{ $t('bet.part-three-details') }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-show="div_name === 'account_web'">
                        <div class="welcome">
                            <h4>{{ $t('my-account-web.my-account') }}</h4>
                            <button class="terms-accordion"
                                @click="toggleAccordian">{{ $t('my-account-web.part-one') }}</button>
                            <div class="terms-conditions">
                                <div class="terms-body">
                                    {{ $t('my-account-web.part-one-details') }}
                                </div>
                            </div>
                            <button class="terms-accordion"
                                @click="toggleAccordian">{{ $t('my-account-web.part-two') }}</button>
                            <div class="terms-conditions">
                                <div class="terms-body">
                                    {{ $t('my-account-web.part-two-details') }}
                                </div>
                            </div>
                            <button class="terms-accordion"
                                @click="toggleAccordian">{{ $t('my-account-web.part-three') }}</button>
                            <div class="terms-conditions">
                                <div class="terms-body">
                                    {{ $t('my-account-web.part-three-details') }}
                                </div>
                            </div>
                            <button class="terms-accordion"
                                @click="toggleAccordian">{{ $t('my-account-web.part-four') }}</button>
                            <div class="terms-conditions">
                                <div class="terms-body">
                                    {{ $t('my-account-web.part-four-details') }}
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'how-to-play',
  data() {
    return {
      div_name: "withdraw_web"
    }
  },
  methods : {
    toggleAccordian: function(event) {
      var element = event.target;
      element.classList.toggle("active");
          
      var panel = element.nextElementSibling;
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + "px";
      }
    },
    showdiv: function(flag) {
      this.div_name = flag;
     
    }
  }
}
</script>

<style scoped>
  .how-to-play {
    display: flex;
}
.how-to-play div {
    flex-direction: row;
    width: 100%;
    padding: 10px;
}
.how-to-play .how-to-play-title {
    text-transform: uppercase;
    border-bottom: 1px solid #fff;
    padding-bottom: 10px;
}
.panel-default.htp {
    background-color: var(--bg-dark-blue);
    border: 1px solid var(--bg-dark-blue);
    color: #fff;
    border-radius: 0px;
    margin-top: 20px;
}
.panel-default.htp .panel-body {
    padding: 5px;
}
.list-unstyled.htp li a {
    color: #fff;
    font-size: 16px;
}
.list-unstyled.htp li a:hover,.list-unstyled.htp li a:active,.list-unstyled.htp li a:focus {
    color: #f7941e;
    font-size: 16px;
    text-decoration: none;
    cursor: pointer;
}
.list-unstyled.htp li {
    padding: 5px 0px;
}
</style>